<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import LoaderIcon from "@/icons/loader.svg?component";
import OnlineIndicator from "@/icons/online-indicator.svg?component";
import AlertCircleOutlineIcon from "@/icons/alert-circle-outline.svg?component";
import { CountUp } from "@/components/ui/count-up";
import { loadI18n, getCurrentLocale } from "@/i18n/i18n.client";
import { serversInfoQuery } from "@/services/masterlist.client";

const locale = getCurrentLocale();
const { t } = await loadI18n(locale, ["landing"]);

const isServer = import.meta.env.SSR;

const { data, isLoading, isError } = useQuery({
	...serversInfoQuery,
	enabled: !isServer,
	refetchInterval: 5000,
});
</script>

<template>
	<div
		v-if="!data && (isLoading || isServer)"
		class="flex h-8 items-center justify-center rounded-full border border-white/10 bg-stone-700 pl-3 pr-3.5 text-center text-sm text-white/50 shadow-lg"
	>
		<LoaderIcon
			aria-hidden="true"
			class="mr-1.5 size-4 animate-spin text-white/50"
		/>
		{{ t("landing:hero.online_badge.loading") }}
	</div>
	<div
		v-else-if="isError"
		class="flex h-8 items-center justify-center rounded-full border border-white/10 bg-stone-700 pl-3 pr-4 text-center text-sm text-red-400 shadow-lg"
	>
		<AlertCircleOutlineIcon class="mr-1.5 size-4 stroke-[1.5] text-red-400" />
		{{ t("landing:hero.online_badge.error") }}
	</div>
	<div
		v-else-if="data"
		class="flex h-8 items-center justify-center rounded-full border border-white/10 bg-stone-700 pl-1.5 pr-3.5 text-center text-sm shadow-lg rtl:pl-3.5 rtl:pr-1.5"
	>
		<OnlineIndicator aria-hidden class="mr-0.5 text-green-400" />
		<p class="text-white/75">
			<CountUp
				:value="data.playersCount"
				as="strong"
				class="font-semibold text-white"
				:options="{
					separator: ' ',
				}"
			/>
			{{
				t("landing:hero.online_badge.players", {
					count: data.playersCount,
				})
			}}
			{{ t("landing:hero.online_badge.on") }}
			<CountUp
				:value="data.serversCount"
				as="strong"
				class="font-semibold text-white"
				:options="{
					separator: ' ',
				}"
			/>
			{{
				t("landing:hero.online_badge.servers", {
					count: data.serversCount,
				})
			}}
		</p>
	</div>
</template>
